import './bootstrap';
import autosize from "autosize";

const tooltipElementList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
const tooltipList = [...tooltipElementList].map(tooltipEl => bootstrap.Tooltip.getOrCreateInstance(tooltipEl))

const popoverElementList = document.querySelectorAll('[data-bs-toggle="popover"]');
const popoverList = [...popoverElementList].map(popoverEl => bootstrap.Popover.getOrCreateInstance(popoverEl))

let modalsElement = document.getElementById('livewire-modal');

autosize(document.querySelectorAll('textarea[data-bs-toggle="autosize"]'));

Livewire.on('showBootstrapModal', (component) => {
  let modalsElement = document.querySelector("[data-component='" + component + "']");
  let modal = bootstrap.Modal.getOrCreateInstance(modalsElement);
  modal.show();

  modalsElement.addEventListener('shown.bs.modal', event => {
    let autofocus = modalsElement.querySelector('[autofocus]');
    if (autofocus) {
      autofocus.focus();
    }

    autosize(modalsElement.querySelectorAll('textarea[data-bs-toggle="autosize"]'));
    initDatePicker();
  });

  modalsElement.addEventListener('hidden.bs.modal', (e) => {
    Livewire.emit('resetModal', e.target.dataset.component);
    console.log('hidden');
  });
});


Livewire.on('hideModal', (component) => {
  console.log('hideModal', component);
  let modalsElement = document.querySelector("[data-component='" + component + "']");
  let modal = bootstrap.Modal.getInstance(modalsElement);
  modal.hide();
});

Livewire.on('autofocus', (element) => {
  document.getElementById(element).focus();
});

// Nested demo
var nestedSortables = [].slice.call(document.querySelectorAll('.nested-sortable'));

// Loop through each nested sortable element
for (var i = 0; i < nestedSortables.length; i++) {
  initSortable(nestedSortables[i]);
}

window.addEventListener('sortable', event => {
  if (event.detail.section) {
    let selector = '.nested-sortable[data-id="' + event.detail.section + '"]';
    let element = document.querySelector(selector);
    initSortable(element);

    // colorpickers
  }
});

// Nested demo
var nestedSortables = [].slice.call(document.querySelectorAll('.nested-sortable'));

// Loop through each nested sortable element
for (var i = 0; i < nestedSortables.length; i++) {
  initSortable(nestedSortables[i]);
}

function initSortable(element) {
  new Sortable(element, {
    group:          element.dataset.group,
    animation:      150,
    fallbackOnBody: true,
    swapThreshold:  0.65,
    handle:         '.drag-handle',
    onChoose:       function (/**Event*/e) {
      e.item.classList.add('choose');
    },

    onUnchoose: function (/**Event*/e) {
      e.item.classList.remove('choose');
    },

    onEnd: function (e) {
      if (e.item.classList.contains('lesson')) {
        if (e.to.dataset.type == 'sub') {
          e.item.classList.add('ps-5');
        } else {
          e.item.classList.remove('ps-5');
        }
      }
    },

    onAdd: function (e) {
      Livewire.emit('updateOrder', e.to.dataset.group, this.el.dataset.id, this.toArray());
    },

    onUpdate: function (e) {
      Livewire.emit('updateOrder', e.to.dataset.group, this.el.dataset.id, this.toArray());
    }
  });
}

Coloris({
  el:           '.coloris',
  clearButton:  true,
  clearLabel:   'Remove color',
  format:       'hex',
  formatToggle: false,
  swatches:     [
    '#0054a6',
    '#4299e1',
    '#4263eb',
    '#ae3ec9',
    '#d6336c',
    '#d63939',
    '#f76707',
    '#f59f00',
    '#74b816',
    '#2fb344',
    '#0ca678',
    '#17a2b8',
    '#ffffff',
    '#e2e8f0',
    '#9ba9be',
    '#667382',
    '#313c52',
    '#0f172a',
    '#000000'
  ]
});

document.addEventListener("DOMContentLoaded", function () {
  initDatePicker();
  initDropdown();
});

function initDropdown() {
  const dropdownElementList = document.querySelectorAll('[data-bs-toggle="dropdown"]');
  const dropdownList = [...dropdownElementList].map(el => {
    new bootstrap.Dropdown(el, {
      boundary:     document.querySelector("body"),
      popperConfig: function (defaultBsPopperConfig) {
        return {
          ...defaultBsPopperConfig,
          strategy: "fixed"
        };
      }
    });
    el.addEventListener('shown.bs.dropdown', event => {
      setTimeout(() => {
        var nextSibling = event.relatedTarget.nextSibling;
        while (nextSibling && nextSibling.nodeType != 1) {
          nextSibling = nextSibling.nextSibling
        }
        var search = nextSibling.getElementsByClassName('search');

        if (search.length > 0) {
          search[0].focus();
        }
      }, 0)
    });
  });
}

window.addEventListener('dropdown', event => {
  initDropdown();
});

function initDatePicker() {
  const datepickerElementList = document.querySelectorAll('.datepicker');
  const datepickerList = [...datepickerElementList].map(datepickerEl => {
    let picker = new Litepicker({
      element:    datepickerEl,
      format:     'DD.MM.YYYY',
      lang:       'de-DE',
      buttonText: {
        previousMonth: `<svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M15 6l-6 6l6 6" /></svg>`,
        nextMonth:     `<svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 6l6 6l-6 6" /></svg>`,
      },
    });
    picker.on('selected', (date1, date2) => {
      datepickerEl.dispatchEvent(new Event('input'));
    });
  });
}

window.addEventListener('toast', event => {
  const toastEl = document.querySelector('.toast')

  if (event.detail.message) {
    toastEl.querySelector('.message').textContent = event.detail.message;
  }

  new bootstrap.Toast(toastEl, {'autohide': true, 'delay': 5000}).show();
});
