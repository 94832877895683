import * as bootstrap from 'bootstrap';

window.bootstrap = bootstrap;

import _ from 'lodash';

window._ = _;

import dayjs from "dayjs";
import localeDe from "dayjs/locale/de";
import localizedFormat from "dayjs/plugin/localizedFormat";
import Alpine from 'alpinejs';
import alpineDayJS from "@invoate/alpine-dayjs";
import persist from '@alpinejs/persist';

dayjs.extend(localizedFormat);
dayjs.locale(localeDe);

Alpine.plugin(alpineDayJS({
  dayjs: dayjs
}));

Alpine.plugin(persist);

window.Alpine = Alpine;
Alpine.start();

import Vimeo from '@vimeo/player';

window.Vimeo = Vimeo;

import Sortable from 'sortablejs';

window.Sortable = Sortable;

import Litepicker from 'litepicker';

window.Litepicker = Litepicker;

import * as tus from 'tus-js-client';

window.tus = tus;

import TomSelect from 'tom-select';

window.TomSelect = TomSelect;

import ClipboardJS from 'clipboard';

window.ClipboardJS = ClipboardJS;

import Plyr from 'plyr';

window.Plyr = Plyr;

import autosize from 'autosize';
window.autosize = autosize;

/* Import TinyMCE */
import tinymce from 'tinymce';

/* Default icons are required. After that, import custom icons if applicable */
import 'tinymce/icons/default/icons.min.js';

/* Required TinyMCE components */
import 'tinymce/themes/silver/theme.min.js';
import 'tinymce/models/dom/model.min.js';

/* Import the skin */
import 'tinymce/skins/ui/oxide/skin.js';

import 'tinymce-i18n/langs6/de.js';

import 'tinymce/plugins/code';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/media';
import 'tinymce/plugins/table';
import 'tinymce/plugins/help';
import 'tinymce/plugins/wordcount';

/* content UI CSS is required */
import 'tinymce/skins/ui/oxide/content.js';
import 'tinymce/skins/content/default/content.js';

/* Import Coloris */
import Coloris from "@melloware/coloris";
window.Coloris = Coloris;
Coloris.init();

/* The default content CSS can be changed or replaced with appropriate CSS for the editor content. */
// import contentCss from 'tinymce/skins/content/default/content.js';

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import axios from 'axios';

window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */


import Echo from 'laravel-echo';

import Pusher from 'pusher-js';

window.Pusher = Pusher;

window.Echo = new Echo({
  broadcaster:       'pusher',
  key:               import.meta.env.VITE_PUSHER_APP_KEY,
  cluster:           import.meta.env.VITE_PUSHER_APP_CLUSTER ?? 'mt1',
  wsHost:            import.meta.env.VITE_PUSHER_HOST ? import.meta.env.VITE_PUSHER_HOST : `ws-${import.meta.env.VITE_PUSHER_APP_CLUSTER}.pusher.com`,
  wsPort:            import.meta.env.VITE_PUSHER_PORT ?? 80,
  wssPort:           import.meta.env.VITE_PUSHER_PORT ?? 443,
  forceTLS:          (import.meta.env.VITE_PUSHER_SCHEME ?? 'https') === 'https',
  enabledTransports: ['ws', 'wss'],
});
